import React, {createContext, useContext, useEffect} from 'react';
import {useMemo} from 'react';
import {useCallback} from 'react';
import {useState} from 'react';
import {Message} from 'react-enty';
import Modal from '../affordance/Modal';
import ModalPending from '../affordance/ModalPending';
import ModalConfirm from '../affordance/ModalConfirm';
import ErrorBoundary from '../error/ErrorBoundary';
import Text from '../affordance/Text';
import Button from '../affordance/Button';
import Toast from '../affordance/Toast';
import Overlay from '../affordance/Overlay';
import Paper from '../affordance/Paper';
import {useHistory} from 'react-router-dom';
import {useAnalytics} from './analytics/AnalyticsContext';

//
// Configs
type MessageConfig = {
    title: string;
    description: React.ReactNode;
    buttons?: (onClose: () => void) => React.ReactNode;
    maxWidth?: string;
};

type ConfirmConfig = {
    title: string;
    subtitle: React.ReactNode;
    yes: (onClose: () => void, isSafe: boolean) => React.ReactNode;
    no?: (onClose: () => void) => React.ReactNode;
    passphrase?: string;
    maxWidth?: string;
};

type CustomConfig = (onRequestClose: () => void) => React.ReactNode;
type CustomOptions = {
    alignItems?: string;
    maxWidth?: string;
    width?: string;
    /** Turn on if you want to prevent overflowing the modal overlay. You will need to setup your
     * own CSS to control possible overflow in your modal.custom implementation */
    disableOverflow?: boolean;
    onClose?: () => void;
    /** Set this to `true` only if you provide an `onClose` callback */
    preventDefaultClose?: boolean;
};

type ToastConfig = {
    type: 'success' | 'error';
    message: string;
    timer?: number;
};

//
// Context
type ModalContextData = {
    close: () => void;
    confirm: (config: ConfirmConfig) => void;
    custom: (config: CustomConfig, options?: CustomOptions) => void;
    message: (config: MessageConfig) => void;
    pending: (title: string, subtitle?: string) => void;
    toast: (config: ToastConfig) => void;
    toastError: (message?: string, options?: {timer?: number; error?: Error}) => void;
    toastSuccess: (message: string, timer?: number) => void;
    useToastPending: (entyMessage: Message<any>, pending: string, done: string) => void;
};
const ModalContext = createContext<ModalContextData | undefined>(undefined);

type OverlayProps = Omit<
    React.ComponentProps<typeof Overlay>,
    'children' | 'isOpen' | 'onRequestClose'
> &
    CustomOptions;
//
// Provider
export function ModalProvider(props: {children: React.ReactNode}) {
    const [modal, setModal] = useState<React.ReactNode>(null);
    const [toast, setToast] = useState<React.ReactNode>(null);
    const onRequestModalClose = useCallback(() => setModal(null), []);
    const onRequestToastClose = useCallback(() => setToast(null), []);

    const analytics = useAnalytics();
    const history = useHistory();

    function setModalWithOverlay(content: React.ReactNode, overlayProps: OverlayProps) {
        setModal(
            <ErrorBoundary>
                <Overlay
                    isOpen={true}
                    showCross={false}
                    onRequestClose={() => {
                        overlayProps?.onClose?.();
                        !overlayProps?.preventDefaultClose && onRequestModalClose();
                    }}
                    {...overlayProps}
                    children={() => content}
                />
            </ErrorBoundary>
        );
    }

    const modalContextData = useMemo(() => {
        const toast = ({type, message, timer = 2000}: ToastConfig) => {
            setToast(
                <Toast
                    type={type}
                    timer={timer}
                    onRequestClose={onRequestToastClose}
                    children={message}
                />
            );
        };
        return {
            close: onRequestModalClose,
            toast,
            toastSuccess: (message: string, timer?: number) =>
                toast({message, timer, type: 'success'}),
            toastError: (
                message = 'Oops, something went wrong!',
                options?: {
                    timer?: number;
                    error?: Error;
                }
            ) => {
                if (options?.error) analytics.trackError(options.error);
                toast({message, timer: options?.timer, type: 'error'});
            },
            useToastPending: (entyMessage: Message<any>, pending: string, done: string) => {
                useEffect(() => {
                    if (entyMessage.isFetching) toast({message: pending, type: 'success'});
                    if (entyMessage.isSuccess) {
                        toast({message: done, type: 'success'});
                        entyMessage.reset();
                    }
                }, [entyMessage.isFetching, entyMessage.isSuccess]);
            },
            custom: (fn: CustomConfig, config: CustomOptions = {}) => {
                setModalWithOverlay(
                    <Paper
                        borderRadius=".5rem"
                        maxHeight="calc(100vh - 4rem)"
                        mx="auto"
                        maxWidth={config.maxWidth}
                        width={config.width}
                        style={config.disableOverflow ? {overflow: 'hidden'} : undefined}
                    >
                        <ErrorBoundary>{fn(onRequestModalClose)}</ErrorBoundary>
                    </Paper>,
                    {
                        maxWidth: config.maxWidth,
                        alignItems: config.alignItems,
                        showCross: true,
                        noOverlayOverflow: config.disableOverflow,
                        onClose: config.onClose,
                        preventDefaultClose: config.preventDefaultClose
                    }
                );
            },
            message: (config: MessageConfig) =>
                setModalWithOverlay(
                    <Modal
                        title={config.title}
                        children={<Text textStyle="heading4">{config.description}</Text>}
                        buttons={config.buttons?.(onRequestModalClose)}
                        onRequestClose={onRequestModalClose}
                        maxWidth={config.maxWidth}
                    />,
                    {maxWidth: config.maxWidth || '24rem'}
                ),
            confirm: (config: ConfirmConfig) =>
                setModalWithOverlay(
                    <ModalConfirm
                        title={config.title}
                        passphrase={config.passphrase}
                        subtitle={config.subtitle}
                        yes={({isSafe}) => config.yes(onRequestModalClose, isSafe)}
                        no={
                            config.no?.(onRequestModalClose) ?? (
                                <Button secondary onClick={onRequestModalClose}>
                                    Cancel
                                </Button>
                            )
                        }
                    />,
                    {maxWidth: config.maxWidth ?? '24rem'}
                ),
            pending: (title: string, subtitle?: string) =>
                setModalWithOverlay(<ModalPending title={title} subtitle={subtitle} />, {
                    maxWidth: '20rem'
                })
        };
    }, []);

    // Handle closing modal when route changes
    useEffect(() => history.listen(() => modalContextData.close()), []);

    return (
        <ModalContext.Provider value={modalContextData}>
            {modal}
            {toast}
            {props.children}
        </ModalContext.Provider>
    );
}

//
// Hook
export function useModal(): ModalContextData {
    const modal = useContext(ModalContext);
    if (!modal) throw new Error('ModalContext used before it exists');
    return modal;
}
