import Numeral from 'numeral';
import format from 'date-fns/fp/format';
import parseISO from 'date-fns/fp/parseISO';
import formatDistance from 'date-fns/formatDistance';
import {formatDistanceToNow, formatDistanceStrict} from 'date-fns';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import isSameDay from 'date-fns/isSameDay';
import isSameMonth from 'date-fns/isSameMonth';
import isSameYear from 'date-fns/isSameYear';
import endOfMonth from 'date-fns/endOfMonth';
import startOfMonth from 'date-fns/startOfMonth';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';

// Because we are using the currying fns from date-fns (e.g. date-fns/fp), we don't have the correct types defined for parse
// @ts-ignore
export const parseDate = () => parseISO;

// @intent - turn a date into a string
export const stringifyDate = (formatString: string) => (date: Date) => format(formatString)(date);

// @intent
// turn a date into a string but also apply logic to
// represent null dates in a friendly way to the user.
export const formatDate =
    (
        formatString: string | 'distance' | 'standard',
        dateFormatNullValue = 'Unknown Date',
        showFutureDates = true,
        exclusive = false
    ) =>
    (date: Dateish) => {
        if (!date) {
            return dateFormatNullValue;
        }
        let value = typeof date === 'string' ? parseISO(date) : date;
        if (exclusive) value = subDays(value, 1);
        const time = value.getTime();

        if (!showFutureDates && value >= new Date()) {
            return `Current`;
        }
        if (formatString === 'standard') return format('dd/MM/yyyy h:mma')(value);
        if (formatString === 'distance') return formatDistanceToNow(value, {addSuffix: true});
        return isNaN(time) || time < 0 ? dateFormatNullValue : format(formatString)(value);
    };

type Dateish = Date | string | null;
type Options = {showFutureDates?: boolean; exclusive?: boolean; hideDays?: boolean};
/**
Format two dates so that we can always show the most condensed version
Jan 1 2020
Jan 1 - 20 2020
Jan 1 - Feb 16 2020
Jan 1 2030 - Jan 31 201

@note We naively wrote this function with just a boolean config.
To make the upgrade path simpler we've added some overloading
*/
export function formatDateRange(from: Dateish, to: Dateish, options?: Options): string;
/** @deprecated showFutureDates as a boolean is deprecated, Use options instead. */
export function formatDateRange(from: Dateish, to: Dateish, showFutureDates?: boolean): string;
export function formatDateRange(
    from: Dateish,
    to: Dateish,
    futureOrOptions: boolean | Options = true
): string {
    let showFutureDates = true;
    let exclusive = false;
    let hideDays = false;
    if (typeof futureOrOptions === 'boolean') {
        showFutureDates = futureOrOptions;
    } else {
        showFutureDates = futureOrOptions.showFutureDates ?? true;
        exclusive = futureOrOptions.exclusive ?? false;
        hideDays = futureOrOptions.hideDays ?? false;
    }
    const monthDayYearExclusive = formatDate('MMM d yyyy', 'Unknown Date', true, exclusive);
    const monthDayYear = formatDate('MMM d yyyy');
    const monthDay = formatDate('MMM d');
    const month = formatDate('MMM');
    const year = formatDate('yyyy');
    const monthYear = formatDate('MMM yyyy');

    if (from && to) {
        const fromDate = new Date(from);
        const toDate = new Date(to);
        const now = new Date();

        // Check to see if dates are the same
        const toDateCheck = exclusive ? subDays(toDate, 1) : toDate;
        if (
            isSameDay(fromDate, toDateCheck) &&
            isSameMonth(fromDate, toDateCheck) &&
            isSameYear(fromDate, toDateCheck)
        ) {
            return monthDayYear(from);
        }

        // check if to is in the future
        if (!showFutureDates && toDate >= now) {
            if (fromDate >= now) {
                // from date is in the future, dont show current to date
                return `${monthDayYear(from)}`;
            }
            return `${monthDayYear(from)} - Current`;
        }

        if (hideDays && Math.abs(differenceInCalendarMonths(fromDate, toDate)) > 0) {
            if (!isSameYear(fromDate, toDate)) return `${monthYear(from)} - ${monthYear(to)}`;
            const formatedFrom = isStartfMonth(fromDate) ? month(from) : monthDay(from);
            const formatedTo = isEndOfMonth(toDate) ? month(to) : monthDay(to);
            return `${formatedFrom} - ${formatedTo} ${year(to)}`;
        }

        // if from and to are the same year
        if (isSameYear(fromDate, toDate)) {
            return `${monthDay(from)} - ${monthDayYearExclusive(to)}`;
        }
    }

    return `${monthDayYear(from)} - ${monthDayYearExclusive(to)}`;
}

export const formatNumber =
    (numberFormat: string, numberFormatNullValue = '-') =>
    (value: number): string => {
        return value == null ? numberFormatNullValue : Numeral(value).format(numberFormat);
    };

export const formatDuration = (from: Date, to: Date): string => {
    const distance = to.getTime() - from.getTime();
    if (distance < 5000) {
        return `${distance / 1000} seconds`;
    }
    return formatDistance(to, from, {includeSeconds: true});
};

/**
Represent two dates as a number of days e.g. 150 days
Note: a day is added to the to date, as date-fns thinks the same day = 0 days
Bigdatr thinks of that as 1 day.
*/
export const formatDurationAsDays = (from: Date, to: Date): string => {
    return formatDistanceStrict(from, addDays(to, 1), {unit: 'day'});
};

const toIso = formatDate('yyyy-MM-dd');
export const isEndOfMonth = (date: string | Date) => {
    const asDate = typeof date === 'string' ? new Date(date) : date;
    return toIso(endOfMonth(asDate)) === toIso(asDate);
};
export const isStartfMonth = (date: string | Date) => {
    const asDate = typeof date === 'string' ? new Date(date) : date;
    return toIso(startOfMonth(asDate)) === toIso(asDate);
};
