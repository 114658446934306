import React from 'react';
import ErrorHandler from './ErrorHandler';
import {AnalyticsContext} from '../core/analytics/AnalyticsContext';
import Analytics from '../core/analytics/Analytics';
import {createContext, useContext} from 'react';
import Boom from './Boom';
import DeadEnd from '../affordance/DeadEnd';
import DeadEndCampaignNotFound from '../appliance/DeadEndCampaignNotFound';
import DeadEndNoTeams from '../appliance/DeadEndNoTeams';
import DeadEndResponseBodyTooLarge from '../appliance/DeadEndResponseBodyTooLarge';
import DeadEndReportNotFound from '../appliance/DeadEndReportNotFound';

type Props = {
    children: any;
    resetKeys?: React.DependencyList;
    showAllErrorMessages?: boolean;
};

type State = {
    error: React.ReactNode | null;
};

const changedArray = (a: React.DependencyList = [], b: React.DependencyList = []) =>
    a.length !== b.length || a.some((item, index) => !Object.is(item, b[index]));

export default class ErrorBoundary extends React.Component<Props, State> {
    static contextType = AnalyticsContext;
    context: Analytics;
    constructor(props: Props) {
        super(props);
        this.state = {error: null};
    }

    componentDidUpdate(prevProps: Props) {
        const {error} = this.state;
        const {resetKeys} = this.props;
        if (error !== null && changedArray(prevProps.resetKeys, resetKeys)) {
            this.setState({error: null});
        }
    }

    componentDidCatch(caught: Error | Boom) {
        const error = (() => {
            if (caught instanceof Boom) {
                return <DeadEnd title={caught.name} children={caught.message} />;
            }

            switch (caught.name) {
                case 'CREATIVE_NOT_FOUND':
                    return (
                        <DeadEnd
                            title="Sorry, we couldn't find this creative"
                            children="We searched everywhere but we couldn't find it"
                        />
                    );
                case 'CAMPAIGN_NOT_FOUND':
                    return <DeadEndCampaignNotFound />;

                case 'REPORT_NOT_FOUND':
                    return <DeadEndReportNotFound />;

                case 'VIEWER_HAS_NO_TEAMS':
                    return <DeadEndNoTeams />;

                case 'RESPONSE_SIZE_TOO_LARGE':
                    return <DeadEndResponseBodyTooLarge />;

                default:
                    this.context.trackError(caught, {});
                    return <ErrorHandler errorObject={caught} />;
            }
        })();

        this.setState({error});
    }
    render() {
        const {error} = this.state;
        const {children} = this.props;
        if (error) return error;
        if (typeof children === 'function') {
            const Child = children;
            return <Child />;
        }
        return children || null;
    }
}

const ErrorBoundaryContext = createContext<{showAllErrorMessages: boolean} | undefined>(undefined);

export const ErrorBoundaryProvider = ErrorBoundaryContext.Provider;
export const useErrorBoundaryContext = () => useContext(ErrorBoundaryContext);
