// Ignoring this because its a pain to extract colors from the style guide to code. In the
// future we might make use of the 200/400/600/800 colors
/* eslint-disable @typescript-eslint/no-unused-vars  */

const purple900 = '#e3d7f0';
const purple800 = '#c7afe2';
const purple700 = '#ab87d3';
const purple600 = '#8f5fc5';
const purple500 = '#7337b6';
const purple400 = '#612e99';
const purple300 = '#4e257c';
const purple200 = '#3c1d5e';
const purple100 = '#291441';

const pink900 = '#f8d1e8';
const pink800 = '#f1a3d0';
const pink700 = '#ea74b9';
const pink600 = '#e346a1';
const pink500 = '#dc188a';
const pink400 = '#b51576';
const pink300 = '#8d1361';
const pink200 = '#8d1361';
const pink100 = '#3e0e38';

const red900 = '#f9ccd3';
const red800 = '#f399a7';
const red700 = '#ec667a';
const red600 = '#e6334e';
const red500 = '#e00022';
const red400 = '#b80222';
const red300 = '#900423';
const red200 = '#670723';
const red100 = '#3f0924';

const orange900 = '#fce1cf';
const orange800 = '#f8c39f';
const orange700 = '#f5a470';
const orange600 = '#f18640';
const orange500 = '#ee6810';
const orange400 = '#c35514';
const orange300 = '#984318';
const orange200 = '#6d301c';
const orange100 = '#421e20';

const yellow900 = '#fcebcc';
const yellow800 = '#fad799';
const yellow700 = '#f7c266';
const yellow600 = '#f5ae33';
const yellow500 = '#f29a00';
const yellow400 = '#c67d07';
const yellow300 = '#9a610e';
const yellow200 = '#6f4416';
const yellow100 = '#43281d';

const green900 = '#ccf8e6';
const green800 = '#99f0ce';
const green700 = '#66e9b5';
const green600 = '#33e19d';
const green500 = '#00da84';
const green400 = '#05b171';
const green300 = '#09875e';
const green200 = '#0e5e4a';
const green100 = '#123437';

const cyan900 = '#ccf6f5';
const cyan800 = '#99eeeb';
const cyan700 = '#66e5e0';
const cyan600 = '#33ddd6';
const cyan500 = '#00d4cc';
const cyan400 = '#05acaa';
const cyan300 = '#098489';
const cyan200 = '#0e5b67';
const cyan100 = '#123346';

const blue900 = '#dedfff';
const blue800 = '#bdbfff';
const blue700 = '#9ca0ff';
const blue600 = '#7b80ff';
const blue500 = '#5a60ff';
const blue400 = '#4d4fd3';
const blue300 = '#3f3ea7';
const blue200 = '#322d7c';
const blue100 = '#241c50';

const grey1 = '#180b25';
const grey2 = '#2b1c39';
const grey3 = '#4e4757';
const grey4 = '#7e7985';
const grey5 = '#aca9af';
const grey6 = '#d6d5d8';
const grey7 = '#ebebeb';

const rawPalette = [
    [blue100, purple100, cyan100, green100, yellow100, orange100, red100, pink100],
    [blue300, purple300, cyan300, green300, yellow300, orange300, red300, pink300],
    [blue500, cyan500, green500, yellow500, orange500, red500, pink500],
    [blue700, purple700, cyan700, green700, yellow700, orange700, red700, pink700],
    [blue900, purple900, cyan900, green900, yellow900, orange900, red900, pink900]
];

// This is shuffled so the really dark and light colors appear towards the end
const palette = [rawPalette[2], rawPalette[3], rawPalette[1], rawPalette[4], rawPalette[0]].flat(1);

// This is ordered from dark to light
const paletteOrdered = rawPalette.flat(1);

// slice off the first and last row of colors,
// they are too dark/light to be useful for chart colors
const restrictedPallete = paletteOrdered.slice(8, paletteOrdered.length - 8);

const colorPickerColors = [
    [purple300, blue300, cyan300, green300, yellow300, orange300, red300, pink300],
    [purple500, blue500, cyan500, green500, yellow500, orange500, red500, pink500],
    [purple700, blue700, cyan700, green700, yellow700, orange700, red700, pink700]
];
export default {
    palette,
    paletteOrdered,
    restrictedPallete,
    colorPickerColors: colorPickerColors.flat(1),

    // We use only a few gradient colors in the wild. Most of the 300/900 colors are here because
    // they are used in the <Message /> component
    // Add more as we need them

    purple900,
    purple700,
    purple: purple500,
    purple400,
    purple300,
    purple100,

    pink: pink500,

    red900,
    red: red500,
    red300,
    red100,

    orange900,
    orange: orange500,
    orange300,

    yellow900,
    yellow: yellow500,

    green900,
    green700,
    green: green500,
    green400,
    green300,
    green100,

    cyan900,
    cyan: cyan500,

    /**
     * This color isn't in the style guide's 'Brand color pallete' which is the base of all colors in this file.
     * Instead its in the 'Monochrome Pallete', which we don't use in the app, but marketing uses in their reports */
    monochromeBlue: '#e5f3ff',
    blue900,
    blue800,
    blue700,
    blue: blue500,
    blue300,
    blue100,

    grey1,
    grey2,
    grey3,
    grey4,
    grey5,
    grey6,
    grey7,

    black: grey1,
    white: '#fff',
    realBlack: '#000',

    // Semantic Colors

    // The bg/fg scale
    // if you imagine all ui elements in a stack. Sometimes you need to lower the importance of
    // something by moving it up or down in the stack. The numbered versions of background and
    // foreground let you move things in a way that works in both dark and light mode.

    // top
    foreground: grey1,
    foreground1: grey2,
    foreground2: grey3,
    foreground3: grey4,
    background: '#ffffff',
    background1: grey7,
    background2: grey6,
    background3: grey5,
    // bottom

    //
    // Named Colors that we don't really know how they fit together
    // We need them, but their names are probably not that clear or helpful
    diffAdd: green900,
    diffRemove: red900,

    /** Use when you need to indicate that an element is interactable */
    backgroundHover: grey7,

    // @todo describe the concept of outline
    outline: grey6,
    outlineDisabled: grey7,

    brand: purple500, // Main brand purple
    brandAlt: blue500, // Main brand blue

    /** Used for button backgrounds which can have an active state. Can be used with
     * `brand` color for a main-color/accent-color combo. */
    active: purple900,
    activeAlt: blue900,

    muted: grey5, // Text that needs to be less important
    link: blue500, // The color of link text
    overlay: 'rgba(0, 0, 0, .8)'
};
