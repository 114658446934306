import React, {useState} from 'react';
import Paper from './Paper';
import Text from './Text';
import Label from './Label';
import Input from './Input';
import {Box} from '../../layout';
import {Flex, FlexColumn} from '../../layout';
import ErrorBoundary from '../error/ErrorBoundary';

export default function ModalConfirm(props: {
    title: string;
    subtitle: React.ReactNode;
    passphrase?: string;
    yes: (args: {isSafe: boolean}) => React.ReactNode;
    no: React.ReactNode;
}) {
    const {title, subtitle, yes, no, passphrase} = props;
    const [confirmText, setConfirmText] = useState('');
    const isSafe = confirmText === passphrase ?? '';
    return (
        <Paper round p={4}>
            <ErrorBoundary>
                <FlexColumn gap={2}>
                    <Text textStyle="heading2">{title}</Text>
                    <Text textStyle="heading4">{subtitle}</Text>
                    {passphrase && (
                        <Box my={3}>
                            <Label>
                                Please type{' '}
                                <Text
                                    textStyle="code"
                                    // Don't allow researchers to select and copy the confirmation text
                                    style={{userSelect: 'none'}}
                                    textAlign="center"
                                    backgroundColor="background1"
                                >
                                    {passphrase}
                                </Text>{' '}
                                in the input below:
                            </Label>

                            <Input
                                value={confirmText}
                                onChange={setConfirmText}
                                width="400px"
                                placeholder="Type in the confirmation text"
                                // Preventing pasting cause we really need the researchers to stop for
                                // a sec and realize what they are going to do will have a big impact
                                onPaste={(e) => e.preventDefault()}
                            />
                        </Box>
                    )}

                    <Flex width="100%" gap={2} justifyContent="flex-end">
                        {no}
                        {yes({isSafe})}
                    </Flex>
                </FlexColumn>
            </ErrorBoundary>
        </Paper>
    );
}
